import React, { useEffect, useRef, useState } from 'react';
import { Flex, AspectRatio, Box } from '@chakra-ui/react';

const Video = ({
  data = {
    videoFile: null,
    videoTitle: null
  },
  src = null,
  useAspectRatio = true,
  maxLoops = 3,
  children,
  ...props
}) => {
  const { videoFile, videoTitle } = data;
  const videoRef = useRef(null);
  const [currentLoop, setCurrentLoop] = useState(0);
  const [showCover, setShowCover] = useState(true);

  useEffect(() => {
    if (videoRef.current === null || maxLoops < 0) {
      return;
    }

    const videoRefCurrent = videoRef.current;

    const interactionObserver = new IntersectionObserver(
      (videoElements) => {
        const thisElement = videoElements.find(
          ({ target }) => target === videoRefCurrent
        );

        if (typeof thisElement === `undefined` || thisElement === null) {
          return;
        }

        if (thisElement.isIntersecting === true) {
          videoRefCurrent.play();
          setShowCover(false);
        } else {
          videoRefCurrent.pause();
        }
      },
      {
        root: null,
        rootMargin: `0px`,
        threshold: 0
      }
    );

    interactionObserver.observe(videoRefCurrent);

    const onEnded = () => {
      if (currentLoop >= maxLoops) {
        videoRefCurrent.currentTime = 0;
        videoRefCurrent.pause();
        videoRefCurrent.removeEventListener(`ended`, onEnded);
        interactionObserver.unobserve(videoRefCurrent);
        setShowCover(true);
        return;
      }

      videoRefCurrent.play();
      setCurrentLoop(currentLoop + 1);
    };

    videoRefCurrent.addEventListener(`ended`, onEnded);

    // eslint-disable-next-line consistent-return
    return () => {
      videoRefCurrent.removeEventListener(`ended`, onEnded);
      interactionObserver.unobserve(videoRefCurrent);
    };
  }, [videoRef, currentLoop, maxLoops]);

  return useAspectRatio === true ? (
    <AspectRatio ratio={16 / 9} borderRadius="2rem" overflow="hidden" mb="12">
      <Box>
        <Flex
          pos="absolute"
          w="100%"
          h="100%"
          top="0"
          left="0"
          opacity={showCover === true ? 1 : 0}
          transition="opacity 0.2s"
          sx={{
            '> .gatsby-image-wrapper': {
              flexGrow: 1
            }
          }}>
          {children}
        </Flex>
        <Box
          ref={videoRef}
          as="video"
          src={src !== null ? src : videoFile.url}
          title={videoTitle}
          controls
          playsInline
          autoPlay={false}
          loop={maxLoops < 0}
          objectFit="cover"
          width="100%"
          height="100%">
          {videoTitle}
        </Box>
      </Box>
    </AspectRatio>
  ) : (
    <Box>
      <Flex
        pos="absolute"
        w="100%"
        h="100%"
        top="0"
        left="0"
        opacity={showCover === true ? 1 : 0}
        transition="opacity 0.2s"
        sx={{
          '> .gatsby-image-wrapper': {
            flexGrow: 1
          }
        }}>
        {children}
      </Flex>
      <Box
        ref={videoRef}
        as="video"
        src={src !== null ? src : videoFile.url}
        playsInline
        muted
        autoPlay={false}
        loop={maxLoops < 0}
        objectFit="cover"
        width="100%"
        height="100%"
        {...props}
      />
    </Box>
  );
};

export default Video;
