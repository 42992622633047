import React from 'react';
import { Grid, GridItem, AspectRatio } from '@chakra-ui/react';
import Image from '@components/atoms/Image';
import Video from '@components/atoms/Video';
import Section from '@components/atoms/Section';
import Wrapper from '../atoms/Wrapper';
import LazyLoad from '../atoms/LazyLoad';

const ThreeMediaGrid = ({ data: { threeMediaGrid } }) => {
  return (
    <Section position="relative">
      <LazyLoad>
        <Wrapper maxWidth="120rem" px={{ base: 2, md: 4 }}>
          <Grid
            templateColumns={{ base: `repeat(12, 1fr)`, lg: `repeat(11, 1fr)` }}
            columnGap={5}
            alignItems={{ md: `end` }}>
            {typeof threeMediaGrid !== `undefined` &&
              threeMediaGrid.map((item, index) => (
                <GridItem
                  key={item.id}
                  display={{
                    base: index === 1 ? `none` : `block`,
                    md: `block`
                  }}
                  colSpan={{ base: 6, md: 4, lg: index === 1 ? 5 : 3 }}
                  mt={{ base: index === 2 ? 10 : 0, md: 0 }}
                  pb={{
                    md: index === 0 || index === 2 ? `3.5rem` : 0
                  }}>
                  {(item.videoFile?.url || item.image) && (
                    <AspectRatio
                      ratio={{
                        base: 2 / 3,
                        md: index === 1 ? 1 / 1 : 2 / 3,
                        lg: index === 1 ? 1 / 1 : 1 / 3
                      }}
                      maxH={{ base: `22.25rem`, md: `47.25rem` }}>
                      {item.videoFile?.url ? (
                        <Video
                          useAspectRatio={false}
                          src={item.videoFile.url}
                          controls={false}
                          maxLoops={1}>
                          <Image image={item.videoCover} loading="eager" />
                        </Video>
                      ) : (
                        <Image image={item.image} />
                      )}
                    </AspectRatio>
                  )}
                </GridItem>
              ))}
          </Grid>
        </Wrapper>
      </LazyLoad>
    </Section>
  );
};

export default ThreeMediaGrid;
