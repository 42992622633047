import React, { useRef, useEffect } from 'react';
import {
  Box,
  Heading,
  Flex,
  VStack,
  Show,
  useMediaQuery
} from '@chakra-ui/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from 'gsap/CustomEase';
import { height as headerHeight } from '@components/organisms/Header';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import Video from '@components/atoms/Video';
import ButtonGroup from '@components/molecules/ButtonGroup';
import homeHeroStage from '@assets/images/home-hero-stage.webp';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger, CustomEase);
}

const HomeHero = ({
  data: {
    titleLineOne,
    titleLineTwo,
    leftImage,
    image,
    videoCover,
    videoFile,
    ctas
  }
}) => {
  const videoUrl = videoFile?.url || null;
  const [shortViewportHeight] = useMediaQuery(`(max-height: 760px)`);

  const containerRef = useRef(null);
  const titleOneRef = useRef(null);
  const titleTwoRef = useRef(null);
  const imageRef = useRef(null);
  const btnGroupRef = useRef(null);

  useEffect(() => {
    const lineOneTl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: `top center`
      },
      delay: 0.3
    });

    const lineTwoTl = gsap.timeline({
      delay: 0.4,
      scrollTrigger: {
        trigger: containerRef.current,
        start: `top center`
      }
    });

    if (titleOneRef.current != null) {
      lineOneTl.to(titleOneRef.current, {
        opacity: 1,
        duration: 0.4
      });
      lineOneTl.to(
        titleOneRef.current,
        {
          scale: 1,
          y: 0,
          duration: 1.5,
          ease: CustomEase.create(
            `custom`,
            `M0,0 C0,0 0.134,1.395 0.389,1.371 0.538,1.356 0.515,0.941 0.665,0.903 0.791,0.87 0.855,0.976 0.933,1 1.01,1.023 1,1 1,1 `
          )
        },
        `<`
      );
    }

    if (titleTwoRef.current != null) {
      lineTwoTl.to(titleTwoRef.current, {
        opacity: 1,
        duration: 0.4
      });
      lineTwoTl.to(
        titleTwoRef.current,
        {
          scale: 1,
          y: 0,
          duration: 1.5,
          ease: CustomEase.create(
            `custom`,
            `M0,0 C0,0 0.134,1.395 0.389,1.371 0.538,1.356 0.515,0.941 0.665,0.903 0.791,0.87 0.855,0.976 0.933,1 1.01,1.023 1,1 1,1 `
          )
        },
        `<`
      );
    }

    if (imageRef.current != null) {
      gsap.to(imageRef.current, {
        opacity: 1,
        scale: 1,
        duration: 0.6,
        ease: `power1.out`,
        delay: 1.5,
        scrollTrigger: {
          trigger: containerRef.current,
          start: `top center`
        }
      });
    }

    if (btnGroupRef.current != null) {
      gsap.to(btnGroupRef.current, {
        opacity: 1,
        duration: 0.6,
        ease: `power1.out`,
        delay: 2,
        scrollTrigger: {
          trigger: containerRef.current,
          start: `top center`
        }
      });
    }
  }, []);

  return (
    <Flex
      as="section"
      ref={containerRef}
      position="relative"
      bg="colours.colour-1"
      minHeight={{ lg: `44rem` }}
      height={{ lg: `100vh` }}>
      <Flex
        position="relative"
        align="center"
        height="100%"
        width={{ base: `100%`, lg: `50%`, xl: `43%` }}
        pt={headerHeight}
        pb={{ base: 12, lg: 28 }}
        backgroundImage={homeHeroStage}
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat">
        <Wrapper>
          <VStack alignItems="normal" spacing="8">
            {(titleLineOne || titleLineTwo) && (
              <Heading
                as="h1"
                fontSize={{
                  base: `4xl`,
                  lg: `5xl`,
                  xl: `6xl`
                }}
                mb="0"
                textTransform="uppercase"
                textAlign="center"
                textShadow="0.75rem 0.75rem 0.5rem #084FB6">
                <Box
                  as="span"
                  ref={titleOneRef}
                  display="block"
                  opacity={0}
                  scale={0}
                  transform="auto"
                  translateY="2rem"
                  willChange="transform, opacity">
                  {titleLineOne}
                </Box>
                <Box
                  as="span"
                  ref={titleTwoRef}
                  display="block"
                  opacity={0}
                  scale={0}
                  transform="auto"
                  translateY="2rem"
                  willChange="transform, opacity">
                  {titleLineTwo}
                </Box>
              </Heading>
            )}
            {leftImage && (
              <Box
                ref={imageRef}
                opacity={0}
                scale={0.8}
                transform="auto"
                width="100%"
                mx="auto"
                maxW={shortViewportHeight ? { lg: `25rem` } : `30rem`}>
                <Image image={leftImage} loading="lazy" />
              </Box>
            )}
            {ctas && ctas.length > 0 && (
              <Box ref={btnGroupRef} opacity={0} willChange="opacity">
                <ButtonGroup
                  ctas={ctas}
                  justify="center"
                  dataLayerType="home_hero"
                  buttonWidth={{ lg: `calc(50% - 1.75rem)` }}
                  size="sm"
                />
              </Box>
            )}
          </VStack>
        </Wrapper>
      </Flex>
      <Show above="lg">
        <Flex
          width={{ lg: `50%`, xl: `57%` }}
          height={{ lg: `100%` }}
          position="relative"
          _after={{
            content: { lg: `""` },
            position: `absolute`,
            top: 0,
            left: 0,
            display: `block`,
            width: `100%`,
            height: `20%`,
            bgGradient: `linear(to-b, #1A3889, transparent)`,
            opacity: 0.75
          }}
          sx={{
            '> .gatsby-image-wrapper': {
              flexGrow: 1
            }
          }}>
          {videoUrl && (
            <Video
              useAspectRatio={false}
              src={videoUrl}
              maxLoops={1}
              controls={false}>
              <Image image={videoCover} loading="eager" />
            </Video>
          )}
          {!videoUrl && image && <Image image={image} loading="eager" />}
        </Flex>
      </Show>
    </Flex>
  );
};

export default HomeHero;
